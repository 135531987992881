import { authApiService as h } from "@waitroom/common-api";
import { logger as u } from "@waitroom/error-service";
import { storageKeys as n, SERVICE_AUTH as f } from "@waitroom/models";
import { tryCatchAsync as d } from "@waitroom/utils";
import { jwtDecode as T } from "jwt-decode";
const g = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
let k = (e = 21) => {
  let t = "", r = crypto.getRandomValues(new Uint8Array(e));
  for (; e--; )
    t += g[r[e] & 63];
  return t;
};
const l = (e) => e.reduce(
  (t, r) => ({
    ...t,
    [r]: !0
  }),
  {}
), i = {
  userId: `anonymous-${k()}`,
  isAuthenticated: !1,
  status: "initialized"
}, y = {
  _refreshPromise: void 0,
  _cache: {},
  config: {},
  init: function(e) {
    this.config = e;
  },
  getAnonymous: () => i,
  getDecodedToken: function(e) {
    if (this._cache[e]) return this._cache[e];
    const t = T(e);
    if (t)
      return this._cache[e] = t, t;
  },
  setup: async function(e, t = !0) {
    const r = e || this.getStorageTokens();
    if (!(r != null && r.accessToken)) return i;
    const o = this.decode(r, 600);
    o != null && o.accessToken && this.saveStorageTokens({
      accessToken: o.accessToken,
      refreshToken: o.refreshToken
    });
    const s = o || (t ? await this.refresh(r.refreshToken) : void 0);
    return s != null && s.accessToken ? s : this.logout();
  },
  getStorageTokens: function() {
    var e;
    return (e = this.config.storage) == null ? void 0 : e.getParsed(
      n.AUTH_TOKENS
    );
  },
  saveStorageTokens: function(e) {
    var t;
    (t = this.config.storage) == null || t.set(n.AUTH_TOKENS, e);
  },
  clearStorageTokens: function() {
    var e;
    (e = this.config.storage) == null || e.remove(n.AUTH_TOKENS);
  },
  isTokenValid: function(e, t = 0) {
    const r = e ? this.getDecodedToken(e) : void 0;
    return !!r && !this.hasExpired(r.exp, t);
  },
  _refreshFn: async function(e) {
    var a;
    if (!e) return;
    const t = await d(
      () => h.refreshToken({
        data: {
          refreshToken: e
        }
      })
    )();
    if ((t == null ? void 0 : t.code) === 401) return;
    const { authToken: r, refreshToken: o } = ((a = t == null ? void 0 : t.data) == null ? void 0 : a.data) || {};
    if (!r) return;
    const s = { accessToken: r, refreshToken: o }, c = this.decode(s);
    if (c)
      return this.saveStorageTokens(s), c;
  },
  refresh: async function(e) {
    var s;
    if (u.service(f, 1, "Refreshing auth token", e), this._refreshPromise) return await this._refreshPromise;
    const t = (s = this.getStorageTokens()) == null ? void 0 : s.refreshToken, r = e || t;
    if (!r) return;
    this._refreshPromise = this._refreshFn(r);
    const o = await this._refreshPromise || await this._refreshFn(t);
    return u.service(f, 1, "Refreshed auth token", o == null ? void 0 : o.refreshToken), this._refreshPromise = void 0, o;
  },
  /** Decode token with expiration tolerance in seconds */
  decode: function(e, t) {
    const {
      userID: r,
      exp: o,
      roleIDs: s = []
    } = this.getDecodedToken(e.accessToken) || {};
    if (!(!r || this.hasExpired(o, t)))
      return {
        ...e,
        userId: r,
        isAuthenticated: !0,
        expires: o,
        agentId: r,
        roles: l(s),
        status: "initialized"
      };
  },
  logout: async function() {
    return await h.logout({ throwError: !1 }), this.clearStorageTokens(), i;
  },
  /** Check if token has expired with expiration tolerance in seconds */
  hasExpired: (e, t = 0) => !e || Date.now() + t * 1e3 > e * 1e3
};
export {
  y as authService
};
