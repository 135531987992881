const s = {
  enabled: !0,
  config: {
    /** Lower value = higher level */
    logLevel: 5,
    /** Filter logs by service */
    services: void 0
  },
  log: function(...e) {
    this.enabled && this.config.logLevel === 5 && console.log(...e);
  },
  logWith: function(e, ...i) {
    this.enabled && this.config.logLevel >= (e ?? 5) && console.log(...i);
  },
  logType: function(e, ...i) {
    this.enabled && console[e](...i);
  },
  service: function(e, i, ...l) {
    const o = this.config.services;
    (!o || o.includes(e)) && this.logWith(i, `[${e}]: `, ...l);
  }
}, t = /* @__PURE__ */ (() => {
  const e = (o, ...n) => {
    i.console && s.logType(o, ...n);
  };
  let i = { console: !0 };
  const l = (o) => {
    throw o;
  };
  return {
    init: (o) => {
      i = { ...i, ...o };
    },
    c: e,
    console: e,
    show: (o, ...n) => console[o](...n),
    report: (o, n = "error") => {
      i.log && i.log(o), e(n, o);
    },
    raise: l,
    rescue: (o, n) => o instanceof n ? o : l(o)
  };
})();
export {
  t as errorService,
  s as logger
};
