var I = Object.defineProperty;
var U = (e, t, n) => t in e ? I(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var m = (e, t, n) => U(e, typeof t != "symbol" ? t + "" : t, n);
import { logger as u, errorService as P } from "@waitroom/error-service";
import { SERVICE_BRAID as x } from "@waitroom/models";
import { parseJson as C, retryWithBackoff as j } from "@waitroom/utils";
const B = (e) => e ? `?${Object.keys(e).map((t) => `${t}=${encodeURIComponent(e[t])}`).join("&")}` : "";
class N {
  constructor() {
    m(this, "handlerRef", { id: -1 });
  }
  get handler() {
    return this.handlerRef.id;
  }
  set handler(t) {
    this.handlerRef.id = t;
  }
  clear() {
    clearTimeout(this.handlerRef.id);
  }
}
function J(e, t, n = new N()) {
  let s = !1;
  const r = () => {
    n.handler = setTimeout(() => {
      e(() => {
        s = !0, n.clear();
      }), s || r();
    }, t);
  };
  return r(), n;
}
var L = window.fetch, H = window.AbortController, E = window.Headers;
async function K(e, t = {}) {
  if (t = { ...t }, t.headers ? t.headers = new E(t.headers) : t.headers = new E(), t.version && console.assert(
    Array.isArray(t.version),
    "fetch(): `version` must be an array"
  ), t.parents && console.assert(
    Array.isArray(t.parents),
    "fetch(): `parents` must be an array"
  ), t.version && t.headers.set(
    "version",
    t.version.map(JSON.stringify).join(", ")
  ), t.parents && t.headers.set(
    "parents",
    t.parents.map(JSON.stringify).join(", ")
  ), t.subscribe && t.headers.set("subscribe", "true"), t.peer && t.headers.set("peer", t.peer), t.headers.set("X-Keep-Alive", "true"), t.cache = "no-cache", t.patches)
    if (console.assert(!t.body, "Cannot send both patches and body"), console.assert(
      typeof t.patches == "object",
      "Patches must be object or array"
    ), Array.isArray(t.patches) || (t.patches = [t.patches]), t.patches.length === 1) {
      let i = t.patches[0];
      t.headers.set("Content-Range", `${i.unit} ${i.range}`), t.headers.set(
        "Content-Length",
        `${new TextEncoder().encode(i.content).length}`
      ), t.body = i.content;
    } else
      t.headers.set("Patches", t.patches.length), t.body = t.patches.map((i) => {
        var g = `content-length: ${new TextEncoder().encode(i.content).length}`, a = `content-range: ${i.unit} ${i.range}`;
        return `${g}\r
${a}\r
\r
${i.content}\r
`;
      }).join(`\r
`);
  var n = t.signal, s = new H();
  t.signal = s.signal, n && n.addEventListener("abort", () => s.abort());
  var r = await L(e, t);
  r.subscribe = c, r.subscription = { [Symbol.asyncIterator]: f };
  const o = r.headers.get("X-Keep-Alive");
  function c(i, g) {
    if (!r.ok) throw new Error("Request returned not ok status:", r.status);
    if (r.bodyUsed)
      throw new Error("This response's body has already been read", r);
    q(
      r.body,
      // Each time something happens, we'll either get a new
      // version back, or an error.
      (a, b) => {
        if (!b)
          i(a);
        else {
          const v = s.signal.aborted;
          if (s.abort(), g) g(b, v);
          else throw "Unhandled network error in subscription";
        }
      },
      o
    );
  }
  function f() {
    var i = !1, g = [], a = null, b = null;
    return {
      async next() {
        if (g.length > 0) return { done: !1, value: g.shift() };
        var v = new Promise((h, $) => {
          a = h, b = $;
        });
        i || (i = !0, c(
          (h) => a(h),
          (h) => b(h)
        ));
        var p = await v;
        return a = (h) => g.push(h), b = (h) => {
          throw h;
        }, { done: !1, value: p };
      }
    };
  }
  return r;
}
async function q(e, t, n) {
  const s = e.getReader(), r = z(t);
  let o;
  if (n) {
    const c = (parseInt(n) + 15) * 1e3;
    o = J(() => r.checkTimeout(c), 1e4), r.keepAlive = !0;
  }
  for (; ; ) {
    let c, f;
    try {
      const i = await s.read();
      c = i.done, f = i.value;
    } catch (i) {
      o && o.clear(), r.timedOut || t(null, i);
      return;
    }
    if (c) {
      console.debug("Connection closed."), o && o.clear(), r.timedOut || t(null, "Connection closed");
      return;
    }
    r.read(f);
  }
}
const z = (e) => ({
  // A parser keeps some parse state
  state: { input: [] },
  // And reports back new versions as soon as they are ready
  cb: e,
  // Timestamp of last input
  last: Date.now(),
  timedOut: !1,
  keepAlive: !1,
  // You give it new input information as soon as you get it, and it will
  // report back with new versions as soon as it finds them.
  read(t) {
    const n = t;
    if (this.last = Date.now(), !(this.keepAlive && n.length > 12 && n[0] === 88 && n[1] == 45 && n[2] === 75 && n[3] === 101 && n[4] === 101 && n[5] === 112 && n[6] === 45 && n[7] === 65 && n[8] === 108 && n[9] === 105 && n[10] === 118 && n[11] === 101))
      for (this.state.input.push(...t); this.state.input.length; ) {
        try {
          this.state = F(this.state);
        } catch (s) {
          this.cb(null, s);
          return;
        }
        if (this.state.result === "success")
          this.cb({
            version: this.state.version,
            parents: this.state.parents,
            body: this.state.body,
            patches: this.state.patches,
            // Output extra_headers if there are some
            extra_headers: S(this.state.headers)
          }), this.state = { input: this.state.input };
        else if (this.state.result === "error") {
          this.cb(null, this.state.message);
          return;
        }
        if (this.state.result == "waiting") break;
      }
  },
  checkTimeout(t) {
    const n = Date.now() - this.last;
    u.log(`Braid interval diff = ${n}`), n > t && (u.service(x, 3, "Braid connection timed out"), this.timedOut || e(null, "Connection timed out"), this.timedOut = !0);
  }
});
function F(e) {
  if (!e.headers) {
    const t = R(e.input);
    if (t.result === "error") return t;
    if (t.result === "waiting")
      return e.result = "waiting", e;
    e.headers = t.headers, e.version = e.headers.version, e.parents = e.headers.parents, e.input = t.input;
  }
  return X(e);
}
function R(e) {
  const t = G(e);
  if (!t) return { result: "waiting" };
  const n = t.header_string, s = n.length, r = {}, o = /(:?[\w-_]+):\s?(.*)\r?\n?/gy;
  let c, f = !1;
  for (; c = o.exec(n); )
    r[c[1].toLowerCase()] = c[2], o.lastIndex === s && (f = !0);
  return f ? ("version" in r && (r.version = JSON.parse("[" + r.version + "]")), "parents" in r && (r.parents = JSON.parse("[" + r.parents + "]")), "patches" in r && (r.patches = JSON.parse(r.patches)), e = t.remaining_bytes, { result: "success", headers: r, input: e }) : {
    result: "error",
    message: 'Parse error in headers: "' + JSON.stringify(n.substr(o.lastIndex)) + '"',
    headers_so_far: r,
    last_index: o.lastIndex,
    headers_length: s
  };
}
function O(e) {
  const t = e.match(/(\S+)( (.*))?/);
  return t && { unit: t[1], range: t[3] || "" };
}
function X(e) {
  const t = parseInt(e.headers["content-length"]);
  if (isNaN(t)) {
    if (e.headers.patches != null) {
      e.patches = e.patches || [];
      let n = e.patches[e.patches.length - 1];
      for (; !(e.patches.length === e.headers.patches && (e.patches.length === 0 || "content" in n)); ) {
        if ((!n || "content" in n) && (n = {}, e.patches.push(n)), !("headers" in n)) {
          const s = R(e.input);
          if (s.result === "error") return s;
          if (s.result === "waiting")
            return e.result = "waiting", e;
          n.headers = s.headers, e.input = s.input;
        }
        {
          if (!("content-length" in n.headers))
            return {
              result: "error",
              message: "no content-length in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          if (!("content-range" in n.headers))
            return {
              result: "error",
              message: "no content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          const s = parseInt(n.headers["content-length"]);
          if (e.input.length < s)
            return e.result = "waiting", e;
          const r = O(n.headers["content-range"]);
          if (!r)
            return {
              result: "error",
              message: "cannot parse content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          n.unit = r.unit, n.range = r.range, n.content = new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, s))
          ), n.extra_headers = S(n.headers), delete n.headers, e.input = e.input.slice(s);
        }
      }
      return e.result = "success", e;
    }
  } else {
    if (t > e.input.length)
      return e.result = "waiting", e;
    if (e.result = "success", e.headers["content-range"]) {
      const n = O(e.headers["content-range"]);
      if (!n)
        return {
          result: "error",
          message: "cannot parse content-range",
          range: e.headers["content-range"]
        };
      e.patches = [
        {
          unit: n.unit,
          range: n.range,
          content: new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, t))
          )
          // Question: Perhaps we should include headers here, like we do for
          // the Patches: N headers below?
          // headers: state.headers
        }
      ];
    } else
      e.body = new TextDecoder("utf-8").decode(
        new Uint8Array(e.input.slice(0, t))
      );
    return e.input = e.input.slice(t), e;
  }
  return {
    result: "error",
    message: "cannot parse body without content-length or patches header"
  };
}
function S(e) {
  const t = Object.assign({}, e), n = [
    "version",
    "parents",
    "patches",
    "content-length",
    "content-range"
  ];
  for (let s = 0; s < n.length; s++)
    delete t[n[s]];
  if (Object.keys(t).length !== 0)
    return t;
}
function G(e) {
  let t = 0;
  for (; e[t] === 13 || e[t] === 10; )
    t++;
  if (t === e.length)
    return null;
  let n = t, s = 0;
  for (; n < e.length; ) {
    if (e[n] === 10 && e[n + 1] === 10) {
      s = 2;
      break;
    }
    if (e[n] === 10 && e[n + 1] === 13 && e[n + 2] === 10) {
      s = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 10) {
      s = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 13 && e[n + 3] === 10) {
      s = 4;
      break;
    }
    n++;
  }
  if (n === e.length)
    return null;
  const r = e.slice(t, n), o = new TextDecoder("utf-8").decode(
    new Uint8Array(r)
  );
  return {
    remaining_bytes: e.slice(n + s),
    header_string: o
  };
}
const Q = K, ee = {
  _connections: {},
  config: { interceptors: {} },
  close: function(e) {
    const t = this._connections[e];
    t && (u.service(x, 3, "braid: close", e), t.abort.abort(), delete this._connections[e]);
  },
  fetch: async function(e) {
    const { onError: t } = this.config.interceptors, {
      key: n,
      method: s = "GET",
      shouldReconnect: r = !0,
      subscribe: o = !0,
      headers: c = {},
      onFetched: f,
      onPatch: i,
      params: g,
      signal: a,
      skipRetryErrorCodes: b = [401, 404],
      ...v
    } = e, p = n || v.url || "";
    if (u.log("braid: fetch", p), this._connections[p]) {
      const y = `Connection already exists ${p}`;
      if (u.service(x, 3, y), !r) throw new Error(y);
      this.close(p);
    }
    const h = B(g), $ = `${v.baseURL}${v.url}${h}`, A = new AbortController();
    if (a) {
      if (a.aborted) return;
      a.addEventListener("abort", () => {
        u.service(x, 3, "braid: abort", p), A.abort(), delete this._connections[p];
      });
    }
    try {
      return await new Promise(
        async (y, T) => {
          await j(() => new Promise(async (_, D) => {
            const k = Q($, {
              method: s,
              headers: c,
              subscribe: o,
              signal: A.signal,
              ...v
            });
            this._connections[p] = {
              abort: A,
              connection: k
            };
            let w;
            try {
              w = await k;
            } catch (l) {
              return T(l);
            }
            if (u.log("braid: connection", w), w.status > 399)
              return T({
                // @ts-expect-error types are wrong
                code: w.code || w.status,
                data: w == null ? void 0 : w.data,
                success: !1,
                error: w
              });
            w.subscribe(
              (l) => {
                if (l.body) {
                  const d = C(l.body);
                  return u.log("braid: body", d), f && f(d), d ? y(d) : T(l);
                }
                l.patches && i && (u.log("braid: patch", l.patches), i(
                  l.patches.map(
                    (d) => ({
                      op: d.unit,
                      path: d.range,
                      value: d.content ? C(d.content) : void 0
                    })
                  )
                ));
              },
              (l, d) => {
                if (d) return y(void 0);
                u.service(x, 1, "Braid error", l), D(l);
              }
            );
          }), {
            retries: -1,
            // skip retry
            filterError: b ? (_) => !b.includes(_ == null ? void 0 : _.status) && !b.includes(_ == null ? void 0 : _.code) : void 0,
            onError: u.log
          });
        }
      );
    } catch (y) {
      if (P.report(y), A.abort(), !t) throw y;
      return t(e, y);
    }
  }
};
export {
  ee as braidService,
  B as buildQueryString,
  J as setIntervalWithTimeout
};
