import { braidService as b } from "@waitroom/braid";
import { braidService as Pe } from "@waitroom/braid";
import { bearerValue as N, httpService as a } from "@waitroom/http-client";
import { httpService as Oe } from "@waitroom/http-client";
let u;
const V = (e) => {
  u = {
    ...u,
    ...e
  };
}, se = (e) => {
  V(e);
}, T = async (e) => typeof e == "function" ? await e() : await e, W = async () => {
  const e = await T(u.getAuthToken);
  return {
    Authorization: e ? N(e) : void 0
  };
}, _ = async () => {
  const [e, t] = await Promise.allSettled([
    T(u.getFingerPrint),
    T(u.getClientDetails)
  ]);
  return {
    "X-Fingerprint": e.status === "fulfilled" ? e.value : "",
    "X-Client-Info": JSON.stringify(t.status === "fulfilled" ? t.value : {}),
    "Accept-Language": "en"
    // TODO: This should be updated to pick the app's language
  };
}, z = (e, t) => `${e || u.baseURL}/${t || u.version}`, J = async (e, t) => ({
  ...await _(),
  ...e ? await W() : {},
  ...t
}), j = (e) => async (t, { version: s, baseURL: i, headers: c, ...f } = {}) => ({
  url: t,
  baseURL: z(i, s),
  headers: await J(e, c),
  ...f
}), g = j(!1), n = j(!0), ne = {
  getFeed: async (e, t, s) => b.fetch(
    await n("/ai-feed/get", {
      ...u.defaultBraidParams,
      ...s,
      params: {
        ...s == null ? void 0 : s.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  event: async (e, t, s) => a.post(
    await n("/ai-feed/event", {
      ...u.defaultBraidParams,
      ...s,
      data: {
        ...s == null ? void 0 : s.data,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  updateFeed: async (e, t) => a.patch(await n(`/ai-feed/${e}`, t)),
  getSummary: async (e, t, s) => a.get(
    await n(
      `/post-session-summaries/by-session-ids/${e}`,
      {
        ...s,
        params: {
          formats: "md",
          ...s == null ? void 0 : s.params,
          sessionRecurrenceID: t
        }
      }
    )
  ),
  internalCopyAIFeed: async (e) => a.post(await n("/ai-feed/copy", e))
}, ie = "/auth", ce = {
  verifyOTP: async (e) => a.post(await g("/auth/verify-otp", e)),
  verifyOAuthOTP: async (e) => a.post(await g("/oauth/verify-otp", e)),
  requestOTP: async (e) => a.post(await g("/auth/request-otp", e)),
  requestOAuthOTP: async (e) => a.post(await g("/oauth/request-otp", e)),
  verifyAuthTokenProvider: async (e) => a.post(await g("/auth/social-token", e)),
  verifyOAuthTokenProvider: async (e) => a.post(await g("/oauth/social-token", e)),
  verifyAuthCodeProvider: async (e) => a.post(await g("/auth/social-code", e)),
  verifyOAuthCodeProvider: async (e) => a.post(await g("/oauth/social-code", e)),
  refreshToken: async (e) => a.put(await g("/auth/refresh-auth-token", e)),
  logout: async (e) => a.post(await n("/auth/logout", e)),
  hostOptIn: async (e) => a.patch(
    await n("/auth/user/host-opt-in", e)
  ),
  user: async (e) => a.get(await n("/auth/user", e))
}, oe = {
  getCalndrLinkEvent: async (e) => a.post({
    url: "https://calndr.link/api/events",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...e.headers
    },
    ...e
  })
}, re = {
  getPresignedUrl: async (e) => a.get(
    await n("/image-uploads/presigned-url", e)
  )
}, v = "/integrations", y = `${v}/salesforce`, w = `${v}/hubspot`, de = {
  getProviders: async (e) => a.get(await g(`${v}/providers`, e)),
  getConnections: async (e) => a.get(
    await n(`${v}/connections`, e)
  ),
  getAction: async (e, t, s) => a.get(
    await n(`${v}/${e}/${t}`, s)
  ),
  callAction: async (e, t, s) => a.post(
    await n(`${v}/${e}/${t}`, s)
  ),
  getFromAbsoluteURL: async (e, t) => a.get(await n("", { url: e, ...t })),
  authorize: async (e) => a.get(
    await n(`${v}/authorize`, e)
  ),
  disconnect: async (e) => a.delete(
    await n(`${v}/disconnect`, e)
  ),
  // Salesforce
  salesforce: {
    createBinding: async (e) => a.post(await n(`${y}/bindings`, e)),
    getBindingById: async (e, t) => a.get(
      await n(
        `${y}/bindings/${e}`,
        t
      )
    ),
    getBindingByIdBraid: async (e, t) => b.fetch(
      await n(`${y}/bindings/${e}`, {
        ...t,
        ...u.defaultBraidParams
      })
    ),
    deleteBinding: async (e, t) => a.delete(
      await n(
        `${y}/bindings/${e}`,
        t
      )
    ),
    getAccountById: async (e, t) => a.get(
      await n(
        `${y}/accounts/${e}`,
        t
      )
    ),
    getOpportunityById: async (e, t) => a.get(
      await n(
        `${y}/opportunities/${e}`,
        t
      )
    ),
    listBindableRecordsAsOptions: async (e) => a.get(
      await n(
        `${y}/-/list-bindable-records-as-options`,
        e
      )
    ),
    createContact: async (e, t) => a.post(
      await n(`${y}/contacts`, {
        data: e,
        ...t
      })
    ),
    getContacts: async (e, t, s) => a.get(
      await n(
        `${y}/contacts/-/list-related-to-record`,
        {
          params: {
            ...s == null ? void 0 : s.params,
            recordId: e,
            recordType: t
          },
          ...s
        }
      )
    ),
    getLeadById: async (e, t) => a.get(
      await n(
        `${y}/leads/${e}`,
        t
      )
    ),
    getByOwnerSessionRecurrence: async (e, t, s, i) => a.get(
      await n(
        `${y}/bindings/-/get-by-owner-session-recurrence`,
        {
          params: {
            ownerId: e,
            sessionId: t,
            sessionRecurrenceId: s,
            ...i == null ? void 0 : i.params
          },
          ...i
        }
      )
    )
  },
  // Hubspot
  hubspot: {
    createBinding: async (e) => a.post(await n(`${w}/bindings`, e)),
    getBindingById: async (e, t) => a.get(
      await n(
        `${w}/bindings/${e}`,
        t
      )
    ),
    getBindingByIdBraid: async (e, t) => b.fetch(
      await n(`${w}/bindings/${e}`, {
        ...t,
        ...u.defaultBraidParams
      })
    ),
    deleteBinding: async (e, t) => a.delete(
      await n(
        `${w}/bindings/${e}`,
        t
      )
    ),
    getCompanyById: async (e, t) => a.get(
      await n(
        `${w}/companies/${e}`,
        t
      )
    ),
    getDealById: async (e, t) => a.get(
      await n(`${w}/deals/${e}`, t)
    ),
    listBindableRecordsAsOptions: async (e) => a.get(
      await n(
        `${w}/-/list-bindable-records-as-options`,
        e
      )
    ),
    getContacts: async (e, t, s) => a.get(
      await n(
        `${w}/contacts/-/list-related-to-record`,
        {
          params: {
            ...s == null ? void 0 : s.params,
            recordId: e,
            recordType: t
          },
          ...s
        }
      )
    ),
    getContactById: async (e, t) => a.get(
      await n(
        `${w}/contacts/${e}`,
        t
      )
    ),
    getByOwnerSessionRecurrence: async (e, t, s, i) => a.get(
      await n(
        `${w}/bindings/-/get-by-owner-session-recurrence`,
        {
          params: {
            ownerId: e,
            sessionId: t,
            sessionRecurrenceId: s,
            ...i == null ? void 0 : i.params
          },
          ...i
        }
      )
    )
  }
};
async function M(e, t) {
  const s = e.getReader();
  let i;
  for (; !(i = await s.read()).done; )
    t(i.value);
}
function X(e) {
  let t, s, i, c = !1;
  return function(d) {
    t === void 0 ? (t = d, s = 0, i = -1) : t = K(t, d);
    const o = t.length;
    let r = 0;
    for (; s < o; ) {
      c && (t[s] === 10 && (r = ++s), c = !1);
      let l = -1;
      for (; s < o && l === -1; ++s)
        switch (t[s]) {
          case 58:
            i === -1 && (i = s - r);
            break;
          case 13:
            c = !0;
          case 10:
            l = s;
            break;
        }
      if (l === -1)
        break;
      e(t.subarray(r, l), i), r = s, i = -1;
    }
    r === o ? t = void 0 : r !== 0 && (t = t.subarray(r), s -= r);
  };
}
function G(e, t, s) {
  let i = D();
  const c = new TextDecoder();
  return function(d, o) {
    if (d.length === 0)
      s == null || s(i), i = D();
    else if (o > 0) {
      const r = c.decode(d.subarray(0, o)), l = o + (d[o + 1] === 32 ? 2 : 1), $ = c.decode(d.subarray(l));
      switch (r) {
        case "data":
          i.data = i.data ? i.data + `
` + $ : $;
          break;
        case "event":
          i.event = $;
          break;
        case "id":
          e(i.id = $);
          break;
        case "retry":
          const A = parseInt($, 10);
          isNaN(A) || t(i.retry = A);
          break;
      }
    }
  };
}
function K(e, t) {
  const s = new Uint8Array(e.length + t.length);
  return s.set(e), s.set(t, e.length), s;
}
function D() {
  return {
    data: "",
    event: "",
    id: "",
    retry: void 0
  };
}
var Q = function(e, t) {
  var s = {};
  for (var i in e) Object.prototype.hasOwnProperty.call(e, i) && t.indexOf(i) < 0 && (s[i] = e[i]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var c = 0, i = Object.getOwnPropertySymbols(e); c < i.length; c++)
      t.indexOf(i[c]) < 0 && Object.prototype.propertyIsEnumerable.call(e, i[c]) && (s[i[c]] = e[i[c]]);
  return s;
};
const R = "text/event-stream", Y = 1e3, q = "last-event-id";
function Z(e, t) {
  var { signal: s, headers: i, onopen: c, onmessage: f, onclose: d, onerror: o, openWhenHidden: r, fetch: l } = t, $ = Q(t, ["signal", "headers", "onopen", "onmessage", "onclose", "onerror", "openWhenHidden", "fetch"]);
  return new Promise((A, F) => {
    const S = Object.assign({}, i);
    S.accept || (S.accept = R);
    let P;
    function E() {
      P.abort(), document.hidden || k();
    }
    r || document.addEventListener("visibilitychange", E);
    let L = Y, O = 0;
    function I() {
      document.removeEventListener("visibilitychange", E), window.clearTimeout(O), P.abort();
    }
    s == null || s.addEventListener("abort", () => {
      I(), A();
    });
    const H = l ?? window.fetch, x = c ?? ee;
    async function k() {
      var C;
      P = new AbortController();
      try {
        const B = await H(e, Object.assign(Object.assign({}, $), { headers: S, signal: P.signal }));
        await x(B), await M(B.body, X(G((h) => {
          h ? S[q] = h : delete S[q];
        }, (h) => {
          L = h;
        }, f))), d == null || d(), I(), A();
      } catch (B) {
        if (!P.signal.aborted)
          try {
            const h = (C = o == null ? void 0 : o(B)) !== null && C !== void 0 ? C : L;
            window.clearTimeout(O), O = window.setTimeout(k, h);
          } catch (h) {
            I(), F(h);
          }
      }
    }
    k();
  });
}
function ee(e) {
  const t = e.headers.get("content-type");
  if (!(t != null && t.startsWith(R)))
    throw new Error(`Expected content-type to be ${R}, Actual: ${t}`);
}
const m = "/memory", ue = {
  ai: async (e, t, s) => {
    const i = await n(`${m}/ai-stream`, t);
    return (u.fetchEventSource ?? Z)(
      `${i.baseURL}${i.url}`,
      {
        method: "POST",
        body: JSON.stringify(i.data),
        headers: i.headers,
        openWhenHidden: !0,
        ...s,
        onmessage: e
      }
    );
  },
  thread: async (e, t) => a.get(
    await n(`${m}/threads/${e}`, t)
  ),
  threads: async (e) => a.get(
    await n(`${m}/threads`, e)
  ),
  stop: async (e) => a.post(
    await n(`${m}/stop`, e)
  ),
  feedback: async (e) => a.patch(
    await n(`${m}/feedback`, e)
  ),
  suggestions: async (e) => a.get(
    await n(`${m}/suggestions`, e)
  )
}, ge = {
  getStatus: async (e, t, s) => a.get(
    await n(
      `/recordings/${e}/recurrence/${t}/status`,
      { ...s, version: "v2.0" }
    )
  ),
  getRecording: async (e, t, s) => a.get(
    await n(
      `/recordings/${e}/recurrence/${t}/recording`,
      { ...s, version: "v2.0" }
    )
  ),
  getPeople: async (e, t, s) => a.get(
    await n(
      `/recordings/${e}/recurrence/${t}/people`,
      { ...s, version: "v2.0" }
    )
  ),
  getEpisodes: async (e, t) => a.get(
    await n(`/recordings/${e}/episodes`, {
      ...t,
      version: "v2.0"
    })
  ),
  getSessionRecordings: async (e, t, s) => a.get(
    await n(
      `/recordings/by-session/${e}/${t}`,
      s
    )
  ),
  getLatestPlayable: async (e) => a.get(
    await n("/recordings/latest-playable", e)
  )
}, ye = {
  getById: async (e, t) => b.fetch(
    await n(`/sessions/get/${e}`, {
      ...u.defaultBraidParams,
      ...t
    })
  ),
  getFeatured: async (e) => a.get(await g("/sessions/featured", e)),
  subscribe: async (e) => a.post(
    await n("/sessions/subscribe", e)
  ),
  unsubscribe: async (e) => a.post(
    await n("/sessions/unsubscribe", e)
  ),
  getCategories: async (e) => a.get(await n("/sessions/category", e)),
  create: async (e) => a.post(await n("/sessions", e)),
  update: async (e, t, s) => a.patch(
    await n(
      `/sessions/${e}/recurrence/${t}`,
      s
    )
  ),
  end: async (e) => a.post(await n(`/meeting/${e}/end`)),
  delete: async (e, t, s) => a.patch(
    await n(
      `sessions/${e}/recurrence/${t}`,
      s
    )
  ),
  getPast: async (e) => a.get(
    await n("/sessions/get-past-sessions", e)
  ),
  getFuture: async (e) => a.get(
    await n("/sessions/get-future-sessions", e)
  ),
  restartSession: async (e, t, s) => a.patch(
    await n(
      `/sessions/${e}/recurrence/${t}/recur`,
      s
    )
  ),
  getSessionsLibraryHistory: async (e) => a.get(
    await n("/sessions/library/history", e)
  ),
  getSessionsLibraryUpcoming: async (e) => a.get(
    await n("/sessions/library/upcoming", e)
  ),
  presence: async (e, t) => b.fetch(
    await n(`/sessions/presence/${e}`, {
      ...u.defaultBraidParams,
      ...t
    })
  ),
  updateParticipantMetadata: async (e, t) => a.patch(
    await n(`meeting/${e}/participant`, t)
  )
}, p = "/sessions/access", le = {
  inReview: async (e) => b.fetch(
    await n(`${p}/in-review`, {
      ...u.defaultBraidParams,
      ...e
    })
  ),
  userSessionRequest: async (e) => b.fetch(
    await n(`${p}/get`, {
      ...u.defaultBraidParams,
      ...e
    })
  ),
  getViewer: async (e) => a.get(await n(`${p}/viewer`, e)),
  getViewerAccessStats: async (e) => a.get(
    await n(`${p}/get-viewer-access-stats`, e)
  ),
  getInReviewViewerAccessStats: async (e) => a.post(
    await n(
      `${p}/get-in-review-access-requests-count`,
      e
    )
  ),
  requestAccess: async (e) => a.post(
    await n(`${p}/request-access`, e)
  ),
  update: async (e) => a.put(await n(`${p}/request`, e)),
  add: async (e) => a.put(await n(`${p}/add`, e)),
  remove: async (e) => a.delete(await n(`${p}/remove`, e))
}, U = "/session-recordings/off-the-record", we = {
  enableOffTheRecord: async (e) => a.post(
    await n(`${U}/enable`, {
      version: "v2.0",
      ...e
    })
  ),
  disableOffTheRecord: async (e) => a.post(
    await n(`${U}/disable`, {
      version: "v2.0",
      ...e
    })
  )
}, pe = {
  getPlans: async (e) => a.get(await g("/billing/plans", { ...e })),
  getPlan: async (e, t) => a.get(
    await g(`/billing/plans/${e}`, {
      ...t
    })
  )
}, fe = {
  getByUserId: async (e, t, s, i = "audience") => a.post(
    await n("/agora/token", {
      data: {
        userId: t,
        sessionId: e,
        role: i,
        expirationTs: s,
        tokenType: "userAccount"
      }
    })
  ),
  getByUid: async (e, t, s, i = "audience") => a.post(
    await n("/agora/token", {
      data: {
        sessionId: e,
        uid: t,
        role: i,
        expirationTs: s,
        tokenType: "uid"
      }
    })
  ),
  getChat: async (e, t, s) => a.get(
    await n("/chat/token", {
      ...s,
      params: {
        ...s == null ? void 0 : s.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  getStream: async (e, t) => a.post(
    await n(`/meeting/${e}/token`, t)
  )
}, he = {
  getById: async (e, t) => a.get(await n(`/users/id/${e}`, t)),
  getCurrent: async (e) => a.get(await n("/users/me", e)),
  update: async (e, { data: t, ...s }) => a.put(
    await n(`/users/id/${e}`, {
      ...s,
      data: {
        userData: t
      }
    })
  ),
  getSubscriptionPlan: async (e, t) => a.get(
    await n(`/users/${e}/plan`, {
      ...t
    })
  ),
  updateSubscriptionPlan: async (e, t) => a.patch(
    await n(`/users/${e}/plan`, {
      ...t
    })
  ),
  reactivateSubscriptionPlan: async (e, t) => a.post(
    await n(`/users/${e}/plan/reactivate`, {
      ...t
    })
  ),
  cancelSubscriptionPlan: async (e, t) => a.post(
    await n(`/users/${e}/plan/cancel`, {
      ...t
    })
  ),
  updatePaymentTransaction: async (e, t) => a.get(
    await n(
      `/users/${e}/update-payment-method-transaction`,
      {
        ...t
      }
    )
  ),
  activateTrial: async (e, t) => a.post(
    await n(`/users/${e}/activate-trial`, {
      ...t
    })
  ),
  createCustomer: async (e, t) => a.post(
    await n(`/users/${e}/customer`, {
      ...t
    })
  ),
  getTransactions: async (e, t) => a.get(
    await n(`/users/${e}/transactions`, {
      ...t
    })
  ),
  getTransactionInvoice: async (e, t, s) => a.get(
    await n(
      `/users/${t}/transactions/${e}/invoice`,
      { ...s }
    )
  ),
  getPaymentDetails: async (e, t) => a.get(
    await n(`/users/${e}/payment-method-details`, {
      ...t
    })
  ),
  requestDelete: async () => a.delete(await n("/users/me"))
}, ve = {
  get: async (e) => a.get(await n("/meeting-types", e))
}, $e = (e) => e == null ? void 0 : e.data, be = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, me = (e) => {
  var c, f;
  if (!e) return;
  let t, s, i = !1;
  if ("headers" in e && "error" in e) {
    const {
      errCode: d,
      statusCode: o,
      message: r
    } = e.error || {};
    t = d || o || e.code || ((c = e.data) == null ? void 0 : c.code), s = ((f = e.data) == null ? void 0 : f.message) || e.message || r;
  } else {
    let {
      code: d,
      message: o,
      success: r
    } = "data" in e ? (e == null ? void 0 : e.data) || {} : e || {};
    "message" in e && o === void 0 && ({ code: d, message: o, success: r } = e), t = d, s = o, i = !!r;
  }
  return {
    code: t,
    message: s,
    success: i
  };
};
export {
  ne as aiApiService,
  ce as authApiService,
  ie as authBaseEndpoint,
  Pe as braidService,
  g as buildApiParams,
  n as buildApiParamsWithAuth,
  J as buildHeaders,
  oe as calendarApiService,
  u as config,
  $e as getApiData,
  be as getApiResponseData,
  W as getAuthHeader,
  z as getBaseURL,
  me as getResponse,
  Oe as httpService,
  re as imageApiService,
  se as initCommonApi,
  de as integrationsApiService,
  ue as memoryApiService,
  ge as recordingApiService,
  le as sessionAccessApiService,
  ye as sessionApiService,
  we as sessionRecordingsApiService,
  pe as subscriptionPlanApiService,
  fe as tokenApiService,
  V as updateConfig,
  he as userApiService,
  ve as userMeetingTypesService
};
