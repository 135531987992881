class S extends Error {
  constructor(R, e = "", t) {
    const I = e + (t ? ` |> ${t.message}` : "");
    super(I), this.code = R, this.message = e, this.error = t, this.message = I, this.name = this.constructor.name, typeof Error.captureStackTrace == "function" ? Error.captureStackTrace(this, this.constructor) : (this.cause = t, this.stack = new Error(e).stack);
  }
}
var T = /* @__PURE__ */ ((E) => (E.GOOGLE = "google", E.FACEBOOK = "facebook", E.TWITTER = "twitter", E.APPLE = "apple", E.REGISTER = "register", E.LOGIN = "login", E))(T || {}), A = /* @__PURE__ */ ((E) => (E.Section = "section", E.Actions = "actions", E.Divider = "divider", E.Input = "input", E.Header = "header", E))(A || {}), O = /* @__PURE__ */ ((E) => (E.Button = "button", E.Checkboxes = "checkboxes", E.EmailTextInput = "email_text_input", E.NumberInput = "number_input", E.PlainTextInput = "plain_text_input", E.RadioButtons = "radio_buttons", E.StaticSelect = "static_select", E.ExternalSelect = "external_select", E.MultiStaticSelect = "multi_static_select", E.MultiExternalSelect = "multi_external_select", E))(O || {});
const v = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  BlockType: A,
  ElementType: O
}, Symbol.toStringTag, { value: "Module" }));
var _ = /* @__PURE__ */ ((E) => (E.FACEBOOK = "facebook", E.INSTAGRAM = "instagram", E.LINKEDIN = "linkedin", E.REDDIT = "reddit", E.RESTREAM = "restream", E.YOUTUBE = "youtube", E.TIKTOK = "tiktok", E.TWITTER = "twitter", E.TWITCH = "twitch", E.WHATSAPP = "whatsapp", E))(_ || {});
const r = {
  twitter: {
    name: "X",
    color: "#000",
    secondaryColor: "#FFF"
  },
  facebook: {
    name: "Facebook",
    color: "#1877F2",
    secondaryColor: "#FFF"
  },
  instagram: {
    name: "Instagram",
    color: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
    secondaryColor: "#FFF"
  },
  linkedin: {
    name: "LinkedIn",
    color: "#0A66C2",
    secondaryColor: "#FFF"
  },
  youtube: {
    name: "YouTube",
    color: "#FF0000",
    secondaryColor: "#FFF"
  },
  tiktok: {
    name: "TikTok",
    color: "#FFFFFF",
    secondaryColor: "#000"
  },
  reddit: {
    name: "Reddit",
    color: "#FF5700",
    secondaryColor: "#FFF"
  },
  restream: {
    name: "Restream",
    color: "#000000",
    secondaryColor: "#FFF"
  },
  twitch: {
    name: "Twitch",
    color: "#6441A4",
    secondaryColor: "#FFF"
  },
  whatsapp: {
    name: "WhatsApp",
    color: "linear-gradient(36deg, rgba(45,182,64,1) 0%, rgba(88,207,99,1) 100%)",
    secondaryColor: "#FFF"
  }
}, h = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" })), G = {
  UNKNOWN: 1001,
  GENERAL: 1002,
  AVATAR_UPLOAD_ERROR: 1010,
  SESSION_ACCESS_DENIED: 1011,
  SESSION_IS_FULL: 1012,
  TRIAL_ALREADY_ACTIVE: 2107,
  SESSION_NOT_FOUND: 2034,
  SESSION_ACCESS_UNAUTHORIZED: 2076,
  SESSION_ACCESS_ALREADY_REQUESTED: 2088,
  SESSION_ACCESS_ALREADY_APPROVED: 2084,
  SESSION_ACCESS_DOESNT_EXIST: 2086,
  // agora errors
  LOCAL_STREAM_AUDIENCE_ERROR: 4e3,
  LOCAL_STREAM_MISSING_UID: 4001,
  CREATE_MIC_CAM_TRACK_ERROR: 4002,
  CREATE_LOCAL_STREAM_ERROR: 4003,
  PUBLISH_UNDEFINED_LOCAL_STREAM_ERROR: 4004,
  PUBLISH_LOCAL_STREAM_UNDEFINED_CLIENT_ERROR: 4005,
  PUBLISH_LOCAL_STREAM_TRACKS_ERROR: 4006,
  SUBSCRIBE_TO_USER_VIDEO_TRACK_ERROR: 4007,
  REMOTE_STREAM_SET_PLAYBACK_DEVICE_ERROR: 4008,
  MISSING_APP_ID: 4009,
  MISSING_TOKEN_ID: 4010,
  MISSING_CHANNEL_ID: 4011,
  CLIENT_JOIN_ERROR: 4012,
  MISSING_STREAM_VIDEO_TRACK: 4013,
  MISSING_STREAM_AUDIO_TRACK: 4014,
  TOGGLE_BEAUTY_EFFECT_ERROR: 4015,
  CLOSE_MIC_VIDEO_TRACK_ERROR: 4016,
  LOAD_DEVICES_ERROR: 4017,
  GRANT_DEVICES_ACCESS_ERROR: 4018,
  TEST_DEVICES_NO_CAMERA_ERROR: 4019,
  TEST_DEVICES_CREATE_CAMERA_TRACK_ERROR: 4020,
  FETCHING_STREAM_TOKEN_ERROR: 4021,
  TOGGLE_MIC_ERROR: 4022,
  TOGGLE_CAMERA_ERROR: 4023,
  SET_CAMERA_CONFIGURATION_ERROR: 4024,
  SET_CAMERA_DEVICE_ERROR: 4025,
  SET_MICROPHONE_DEVICE_ERROR: 4026,
  SET_REMOTE_VIDEO_STREAM_TYPE_ERROR: 4027,
  SUBSCRIBE_TO_USER_AUDIO_TRACK_ERROR: 4028,
  CREATE_CLIENT_ERROR: 4029,
  SET_STREAM_FALLBACK_OPTION_ERROR: 4030,
  GENERAL_STREAM_ERROR: 4031,
  PUBLISH_AUDIENCE_LOCAL_STREAM_ERROR: 4032,
  CLIENT_JOIN_UNCOMPLETED_ERROR: 4033,
  NOT_COMPATIBLE_ERROR: 4034,
  MISSING_STREAM_VIDEO_AUDIO_TRACK: 4035,
  CLIENT_NOT_INITIALIZED: 4036,
  MISSING_DEFAULT_DEVICE: 4037,
  CREATE_MIC_CAM_TRACK_FALLBACK_ERROR: 4038,
  ERROR_REFRESHING_LIVE_STREAM: 4039,
  ERROR_STOPPING_LIVE_STREAM: 4040,
  MISSING_VIDEO_AUDIO_STORE: 4041,
  FAILED_TO_PLAY_DEVICES: 4042,
  REMOTE_CLIENT_JOIN_ERROR: 4043,
  LOCAL_CLIENT_JOIN_ERROR: 4044,
  CLIENTS_JOIN_ERROR: 4045,
  SET_ENABLED_ERROR: 4046,
  SET_MUTED_ERROR: 4047,
  MISSING_SCREEN_SHARE_STORE: 4048,
  CREATE_SCREEN_SHARE_STREAM_ERROR: 4049,
  SCREEN_SHARE_STREAM_MISSING_UID: 4050,
  LOCAL_STREAM_IS_NOT_AVAILABLE: 4051,
  LOCAL_STREAM_VIDEO_TRACK_IS_NOT_AVAILABLE: 4052,
  MISSING_MICROPHONE_ONLY_STORE: 4053,
  TRACKS_NOT_PROPERLY_RELEASED_ERROR: 4054,
  BROADCAST_STORE_ERROR: 4055,
  INIT_TRACK_EFFECTS_ERROR: 4056,
  RELEASE_CAMERA_TRACK_ERROR: 4057,
  RELEASE_MICROPHONE_TRACK_ERROR: 4058,
  RELEASE_SCREEN_SHARE_TRACK_ERROR: 4059,
  ENABLE_DUAL_STREAM_ERROR: 4060
};
var n = /* @__PURE__ */ ((E) => (E.NotStarted = "Not Started", E.InProgress = "In Progress", E.Completed = "Completed", E.WaitingOnSomeoneElse = "Waiting on someone else", E.Deferred = "Deferred", E))(n || {}), C = /* @__PURE__ */ ((E) => (E.Call = "Call", E.Meeting = "Meeting", E.Other = "Other", E.Email = "Email", E))(C || {}), N = /* @__PURE__ */ ((E) => (E.Lead = "lead", E.Account = "account", E.Opportunity = "opportunity", E))(N || {}), a = /* @__PURE__ */ ((E) => (E.Contact = "contact", E.Company = "company", E.Deal = "deal", E))(a || {});
const K = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" }));
var i = /* @__PURE__ */ ((E) => (E.one = "one", E.group = "group", E))(i || {}), D = /* @__PURE__ */ ((E) => (E[E.portrait = 0] = "portrait", E[E.landscape = 1] = "landscape", E))(D || {}), L = /* @__PURE__ */ ((E) => (E.HLS = ".m3u8", E.MPD = ".mpd", E))(L || {}), c = /* @__PURE__ */ ((E) => (E.DISABLED = "DISABLED", E.PENDING = "PENDING", E.READY = "READY", E))(c || {}), s = /* @__PURE__ */ ((E) => (E[E.NONE = 0] = "NONE", E[E.DAILY = 1] = "DAILY", E[E.WEEKLY = 2] = "WEEKLY", E[E.MONTHLY = 3] = "MONTHLY", E[E.YEARLY = 4] = "YEARLY", E))(s || {});
class o {
  constructor(R, e, t) {
    this.success = R, this.error = e, this.value = t, Object.freeze(this);
  }
  getValue() {
    if (!this.success)
      throw new Error("Cant retrieve the value from a failed result.");
    return this.value;
  }
  getError() {
    if (this.success)
      throw new Error("Cant retrieve the error from a successful result.");
    return this.error;
  }
  static ok(R) {
    return new o(!0, void 0, R);
  }
  static fail(R, e = "") {
    return new o(!1, new S(R, e));
  }
  static error(R) {
    return new o(!1, R);
  }
}
var M = /* @__PURE__ */ ((E) => (E.ACTIVE_STREAMS = "active_streams", E.ADMIN = "admin", E.DEVELOPER = "developer", E.EVERYONE_ANON = "everyone_anon", E.EVERYONE_AUTHED = "everyone_authed", E.GUEST = "guest", E.HOST = "host", E.PRODUCER = "producer", E.ROOMBOT = "roombot", E.SESSION_INFO_VIEWER = "session_info_viewer", E.SESSION_OWNER = "session_owner", E.SESSION_PRODUCER = "session_producer", E.VIEWER = "viewer", E.USER_MANAGER = "user_manager", E))(M || {});
const w = {
  active_streams: !0,
  guest: !0,
  host: !0,
  session_info_viewer: !0,
  session_producer: !0,
  viewer: !0
}, V = "Authentication", g = "Braid";
var d = /* @__PURE__ */ ((E) => (E.PartyMode = "party-mode", E.QueueMode = "queue-mode", E))(d || {});
const b = [
  "everyone",
  "owner",
  "owner_same_domain"
];
var l = /* @__PURE__ */ ((E) => (E.CANCELLED = "cancelled", E.SCHEDULED = "scheduled", E.IN_SESSION = "active", E.ENDED = "ended", E.ARCHIVED = "archived", E))(l || {}), u = /* @__PURE__ */ ((E) => (E.IN_REVIEW = "inReview", E.GRANTED = "granted", E.IGNORED = "ignored", E.DENIED = "denied", E))(u || {}), F = /* @__PURE__ */ ((E) => (E.Google = "google", E))(F || {});
const m = {
  [_.TWITTER]: r.twitter,
  [_.LINKEDIN]: r.linkedin,
  [_.INSTAGRAM]: r.instagram,
  [_.FACEBOOK]: r.facebook,
  [_.YOUTUBE]: r.youtube,
  [_.TIKTOK]: r.tiktok,
  [_.REDDIT]: r.reddit,
  email: { name: "email", color: "#DDDDDD", secondaryColor: "#1E2431" },
  website: { name: "website", color: "#DDDDDD", secondaryColor: "#1E2431" }
}, H = Object.keys(m), p = {
  [_.FACEBOOK]: ({ url: E = "" } = {}) => `//www.facebook.com/sharer.php?u=${encodeURIComponent(E)}`,
  [_.LINKEDIN]: ({ url: E = "" } = {}) => `//www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(E)}`,
  [_.WHATSAPP]: ({ text: E = "" } = {}) => `//wa.me/?text=${encodeURIComponent(E)}`,
  [_.TWITTER]: ({ text: E = "" } = {}) => `//twitter.com/intent/tweet?text=${encodeURIComponent(
    E
  )}&hashtags=waitroom`
};
var U = /* @__PURE__ */ ((E) => (E[E.NONE = 0] = "NONE", E[E.DISABLED = 1] = "DISABLED", E[E.ENABLED = 2] = "ENABLED", E))(U || {});
const Y = {
  AUTH_TOKENS: "AUTH_TOKENS",
  BROWSER_SUPPORT: "BROWSER_SUPPORT",
  DEVICE_SETTINGS: "DEVICE_SETTINGS",
  DEVICES: "DEVICES",
  GENERAL: "g",
  LAST_USED_INTEGRATION: "LAST_USED_INTEGRATION",
  NOTIFICATIONS: "NOTIFICATIONS",
  PLAYER: "PLAYER",
  STREAM_SETTINGS: "STREAM_SETTINGS",
  TEST_CAM_MIC: "TEST_CAM_MIC",
  USER_SETTINGS: "USER_SETTINGS"
  // user session settings
};
var P = /* @__PURE__ */ ((E) => (E[E.MALE = 0] = "MALE", E[E.FEMALE = 1] = "FEMALE", E[E.OTHER = 2] = "OTHER", E))(P || {});
export {
  S as AppError,
  T as AuthType,
  v as BlockKit,
  _ as Brand,
  r as Brands,
  h as CMS,
  G as ErrorCodes,
  P as Gender,
  a as HubspotRecordType,
  K as MeetingMemory,
  d as MeetingType,
  i as MessageBroadcastType,
  D as Orientation,
  c as RecordingStatus,
  s as Recurring,
  o as Result,
  M as Role,
  V as SERVICE_AUTH,
  g as SERVICE_BRAID,
  N as SalesforceRecordType,
  n as SalesforceTaskStatus,
  C as SalesforceTaskType,
  u as SessionAccessRuleStatus,
  F as SessionCalendarType,
  l as SessionStatus,
  p as Share,
  H as SocialKeys,
  m as Socials,
  U as Status,
  L as VideoFileExtension,
  w as rolesWithId,
  Y as storageKeys,
  b as summaryEmailRecipients
};
